const routers = [
  {
    path: "/",
    redirect: "/auth",
  },

  {
    component: "AuthLayout",
    path: "/auth",
    auth: false,
    name: "Auth",
    exact: false,
    redirect: "/auth/login",
    childrens: [
      {
        component: "Login",
        path: "/login",
        componentPath: "pages/Auth/Login",
        name: "Login",
        auth: false,
        exact: true,
      },
      {
        component: "SmartClinic",
        path: "/sc-login",
        componentPath: "pages/Auth/SmartClinic",
        name: "SmartClinic",
        auth: false,
        exact: true,
        childrens: []
      },
    ],
  },




  {
    component: "MainLayout",
    path: "/doctor",
    auth: false,
    name: "Auth",
    exact: false,
    redirect: "/doctor/support",
    childrens: [

      {
        component: "BasicDetails",
        path: "/create-basic-details",
        componentPath:
          "pages/Dashboard/DoctorManagement/DoctorCreation/BasicDetails",
        name: "BasicDetails",
        auth: false,
        exact: true,
      },
      {
        component: "BasicDetails",
        path: "/edit-basic-details/:id?",
        componentPath:
          "pages/Dashboard/DoctorManagement/EditDoctor/BasicDetails",
        name: "EditBasicDetails",
        auth: false,
        exact: true,
      },
      {
        component: "EducationDetails",
        path: "/create-education-details/:id?",
        componentPath:
          "pages/Dashboard/DoctorManagement/DoctorCreation/EducationDetails",
        name: "EducationDetails",
        auth: false,
        exact: true,
      },
      {
        component: "EducationDetails",
        path: "/edit-education-details/:id?",
        componentPath:
          "pages/Dashboard/DoctorManagement/EditDoctor/EducationDetails",
        name: "EditEducationDetails",
        auth: false,
        exact: true,
      },
      {
        component: "Appointment",
        path: "/create-appointment/:id?",
        componentPath:
          "pages/Dashboard/DoctorManagement/DoctorCreation/Appointment",
        name: "CreateDoctorAppointment",
        auth: false,
        exact: true,
      },
      {
        component: "Appointment",
        path: "/edit-appointment/:id?",
        componentPath:
          "pages/Dashboard/DoctorManagement/EditDoctor/Appointment",
        name: "EditAppointment",
        auth: false,
        exact: true,
      },
      {
        component: "PaymentDetails",
        path: "/create-payment-details/:id?",
        componentPath:
          "pages/Dashboard/DoctorManagement/DoctorCreation/PaymentDetails",
        name: "CreatePaymentDetails",
        auth: false,
        exact: true,
      },
      {
        component: "PaymentDetails",
        path: "/edit-payment-details/:id?",
        componentPath:
          "pages/Dashboard/DoctorManagement/EditDoctor/PaymentDetails",
        name: "EditPaymentDetails",
        auth: false,
        exact: true,
      },
      {
        component: "Preview",
        path: "/details-preview/:id?",
        componentPath:
          "pages/Dashboard/DoctorManagement/DoctorCreation/Preview",
        name: "PreviewDetails",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "MainLayout",
    path: "/admin",
    auth: false,
    name: "Dashboard",
    exact: false,
    redirect: "/admin/dashboard",
    childrens: [
      // DASHBOARD
      {
        component: "GetPatientByNumberTemp",
        path: "/getPatientDetails",
        componentPath: "pages/Dashboard/PatientDetails/temp",
        name: "GetPatientByNumberTemp",
        auth: true,
        exact: true,
      },
      {
        component: "GetPatientByNumber",
        path: "/getPatientDetailsByNumber",
        componentPath: "pages/Dashboard/PatientDetails",
        name: "GetPatientByNumber",
        auth: true,
        exact: true,
      },

      {
        component: "GetPatientByNumber",
        path: "/getPatientDetailsByNumber_revamp",
        componentPath: "pages/Dashboard/Calling_Dashboard",
        name: "Calling_Dashboard",
        auth: true,
        exact: true,
      },
      {
        component: "BasicDetails",
        path: "/support",
        componentPath:
          "pages/Dashboard/InboundWorkPage/InboundWorkPage",
        name: "InboundWork",
        auth: false,
        exact: true,
      },

      {
        component: "BasicDetails",
        path: "/smartClinicForm",
        componentPath:
          "pages/Dashboard/SmartClinicManagement/SmartClinicForm",
        name: "smartClinicForm",
        auth: false,
        exact: true,
      },
      {
        component: "Configuration",
        path: "/Configuration",
        componentPath:
          "pages/Dashboard/Configuration/Configuration",
        name: "Configuration",
        auth: false,
        exact: true,
      },

      {
        component: "BasicDetails",
        path: "/lead-management",
        componentPath:
          "pages/Dashboard/InboundWorkPage/LeadsDashboardPage",
        name: "leadsDashBoard",
        auth: false,
        exact: true,
      },
      {
        component: "BasicDetails",
        path: "/smart-clinic-management",
        componentPath:
          "pages/Dashboard/SmartClinicManagement/LeadsManagement",
        name: "SmartClinicDashBoard",
        auth: false,
        exact: true,
      },
      {
        component: "BasicDetails",
        path: "/Outbound_Calling",
        componentPath:
          "pages/Dashboard/OutboundCalling/Dashboard_page",
        name: "OutboundCalling",
        auth: false,
        exact: true,
      },

      {
        component: "BasicDetails",
        path: "/fulFillment-form",
        componentPath:
          "pages/Dashboard/FulFillment/FulFillmentForm",
        name: "FulFillmentForm",
        auth: false,
        exact: true,
      },
      {
        component: "BasicDetails",
        path: "/fulFillment-management",
        componentPath:
          "pages/Dashboard/FulFillment/FulFillmentManagement",
        name: "FulFillmentManagement",
        auth: false,
        exact: true,
      },
      {
        component: "BasicDetails",
        path: "/support/create-lead",
        componentPath:
          "pages/Dashboard/InboundWorkPage/CreateLeadPage",
        name: "CreateLeadPage",
        auth: false,
        exact: true,
      },
      {
        component: "BasicDetails",
        path: "/support/callLog/:id",
        componentPath:
          "pages/Dashboard/InboundWorkPage/CallLogPage",
        name: "CallLogPage",
        auth: false,
        exact: true,
      },
      {
        component: "Dashboard",
        path: "/dashboard",
        componentPath: "pages/Dashboard/InitDashboard/Dashboard",
        name: "Dashboard",
        auth: false,
        exact: true,
      },
      {
        component: "TeamSupport",
        path: "/onboarding",
        componentPath:
          "pages/Dashboard/TeamSupport",
        name: "Team Support",
        auth: false,
        exact: true,
      },
      {
        component: "PatientManagement",
        path: "/patient-management",
        componentPath: "pages/Dashboard/PatientManagement/PatientManagement",
        name: "PatientManagement",
        auth: false,
        exact: true,
      },
      {
        component: "ViewSubcriptionPlan",
        path: "/view-patient-subcription/:id",
        componentPath: "pages/Dashboard/PatientManagement/ViewSubcriptionPlan",
        name: "ViewSubcriptionPlan",
        auth: false,
        exact: true,
      },
      {
        component: "UploadPatient",
        path: "/upload-patient-list",
        componentPath: "pages/Dashboard/PatientManagement/UploadPatient",
        name: "UploadPatient",
        auth: false,
        exact: true,
      },
      {
        component: "PatientProfile",
        path: "/patient-profile/:id",
        componentPath: "pages/Dashboard/PatientManagement/PatientProfile",
        name: "PatientProfile",
        auth: false,
        exact: true,
      },
      {
        component: "PatientProfile",
        path: "/patient-profile",
        componentPath: "pages/Dashboard/PatientManagement/PatientProfile",
        name: "PatientProfile",
        auth: false,
        exact: true,
      },
      {
        component: "ConsultationDetails",
        path: "/patient-consultation-details/:id",
        componentPath: "pages/Dashboard/PatientManagement/ConsultationDetails",
        name: "ConsultationDetails",
        auth: false,
        exact: true,
      },
      {
        component: "FamilyConsultation",
        path: "/family-consultation-details",
        componentPath: "pages/Dashboard/PatientManagement/FamilyConsultation",
        name: "FamilyConsultation",
        auth: false,
        exact: true,
      },
      {
        component: "FamilyMember",
        path: "/add-family-member",
        componentPath: "pages/Dashboard/PatientManagement/FamilyMembers",
        name: "FamilyMember",
        auth: false,
        exact: true,
      },
      {
        component: "FamilyMemberDetails",
        path: "/member-patient-profile/:id",
        componentPath: "pages/Dashboard/PatientManagement/FamilyMemberDetails",
        name: "FamilyMemberDetails",
        auth: true,
        exact: true,
      },
      {
        component: "Appointment",
        path: "/appointment",
        componentPath: "pages/Dashboard/Appointment/Appointment",
        name: "Appointment",
        auth: false,
        exact: true,
      },
      {
        component: "CreateAppointment",
        path: "/create-appointment",
        componentPath: "pages/Dashboard/Appointment/CreateAppointment",
        name: "CreateAppointment",
        auth: false,
        exact: true,
      },
      {
        component: "AppointmentDetails",
        path: "/appointment-details/:id",
        componentPath: "pages/Dashboard/Appointment/AppointmentDetails",
        name: "AppointmentDetails",
        auth: false,
        exact: true,
      },
      {
        component: "VideoCallLogDetails",
        path: "/appointment-video-log/:id",
        componentPath: "pages/Dashboard/Appointment/VideoCallLog",
        name: "VideoCallLogDetails",
        auth: false,
        exact: true,
      },
      {
        component: "VoiceCallLogDetails",
        path: "/appointment-voice-log/:id",
        componentPath: "pages/Dashboard/Appointment/VoiceCallLog",
        name: "VoiceCallLogDetails",
        auth: false,
        exact: true,
      },
      {
        component: "DoctorManagement",
        path: "/doctor-management",
        componentPath: "pages/Dashboard/DoctorManagement/DoctorManagement",
        name: "DoctorManagement",
        auth: false,
        exact: true,
      },
      {
        component: "AddDoctor",
        path: "/add-doctor",
        componentPath: "pages/Dashboard/DoctorManagement/AddDoctor",
        name: "AddDoctor",
        auth: false,
        exact: true,
      },
      {
        component: "InviteDoctor",
        path: "/invite-doctor",
        componentPath: "pages/Dashboard/DoctorManagement/InviteDoctor",
        name: "InviteDoctor",
        auth: false,
        exact: true,
      },
      {
        component: "DoctorProfile",
        path: "/doctor-profile/:id",
        componentPath: "pages/Dashboard/DoctorManagement/DoctorProfile",
        name: "DoctorProfile",
        auth: false,
        exact: true,
      },
      {
        component: "RosterManagement",
        path: "/roster-management",
        componentPath: "pages/Dashboard/RosterManagement/RosterManagement",
        name: "RosterManagement",
        auth: false,
        exact: true,
      },
      {
        component: "UploadRoaster",
        path: "/upload-roaster",
        componentPath: "pages/Dashboard/RosterManagement/UploadRoaster",
        name: "UploadRoaster",
        auth: false,
        exact: true,
      },
      {
        component: "ContentManagement",
        path: "/content-management",
        componentPath: "pages/Dashboard/ContentManagement/ContentManagement",
        name: "ContentManagement",
        auth: false,
        exact: true,
      },

      {
        component: "AddNewBlog",
        path: "/add-new-blog",
        componentPath: "pages/Dashboard/ContentManagement/AddNewBlog",
        name: "AddNewBlog",
        auth: false,
        exact: true,
      },
      {
        component: "AddNewContent",
        path: "/add-new-content",
        componentPath: "pages/Dashboard/ContentManagement/AddNewContent",
        name: "AddNewContent",
        auth: false,
        exact: true,
      },
      {
        component: "ClinicalManagement",
        path: "/clinical-management",
        componentPath: "pages/Dashboard/ClinicalManagement/ClinicalManagement",
        name: "ClinicalManagement",
        auth: false,
        exact: true,
      },
      {
        component: "AddNewSpeciality",
        path: "/add-new-speciality",
        componentPath: "pages/Dashboard/AddNewSpeciality/AddNewSpeciality",
        name: "AddNewSpeciality",
        auth: false,
        exact: true,
      },
      {
        component: "StaffManagement",
        path: "/staff-management",
        componentPath: "pages/Dashboard/StaffManagement/StaffManagement",
        name: "StaffManagement",
        auth: false,
        exact: true,
      },
      {
        component: "AddNewRole",
        path: "/add-new-role",
        componentPath: "pages/Dashboard/StaffManagement/AddNewRole",
        name: "AddNewRole",
        auth: false,
        exact: true,
      },
      {
        component: "AddNewStaff",
        path: "/add-new-staff",
        componentPath: "pages/Dashboard/StaffManagement/AddNewStaff",
        name: "AddNewStaff",
        auth: false,
        exact: true,
      },
      {
        component: "EditRole",
        path: "/edit-role/:id",
        componentPath: "pages/Dashboard/StaffManagement/EditRole",
        name: "EditRole",
        auth: false,
        exact: true,
      },
      {
        component: "ViewRole",
        path: "/view-role/:id",
        componentPath: "pages/Dashboard/StaffManagement/ViewRole",
        name: "ViewRole",
        auth: false,
        exact: true,
      },
      {
        component: "EditPendingApproval",
        path: "/edit-pendingapproval/:id",
        componentPath: "pages/Dashboard/StaffManagement/EditPendingApproval",
        name: "EditPendingApproval",
        auth: false,
        exact: true,
      },
      {
        component: "ViewPendingApproval",
        path: "/view-pendingapproval/:id",
        componentPath: "pages/Dashboard/StaffManagement/ViewPendingApproval",
        name: "ViewPendingApproval",
        auth: false,
        exact: true,
      },
      {
        component: "EditStaff",
        path: "/edit-staff/:id",
        componentPath: "pages/Dashboard/StaffManagement/EditStaff",
        name: "EditStaff",
        auth: false,
        exact: true,
      },
      {
        component: "ViewStaff",
        path: "/view-staff/:id",
        componentPath: "pages/Dashboard/StaffManagement/ViewStaff",
        name: "ViewStaff",
        auth: false,
        exact: true,
      },
      {
        component: "Settings",
        path: "/settings",
        componentPath: "pages/Dashboard/Settings/Settings",
        name: "Settings",
        auth: false,
        exact: true,
      },
      {
        component: "EditBlog",
        path: "/edit-blog/:id?",
        componentPath: "pages/Dashboard/ContentManagement/EditBlog",
        name: "EditBlog",
        auth: false,
        exact: true,
      },
      {
        component: "EditContent",
        path: "/edit-content/:id?",
        componentPath: "pages/Dashboard/ContentManagement/EditContent",
        name: "EditContent",
        auth: false,
        exact: true,
      },
      {
        component: "CorporateManagement",
        path: "/corporate-management",
        componentPath:
          "pages/Dashboard/CorporateManagement/CorporateManagement",
        name: "CorporateManagement",
        auth: false,
        exact: true,
      },
      {
        component: "ReferralManagement",
        path: "/referral-management",
        componentPath: "pages/Dashboard/ReferralManagement/ReferralManagement",
        name: "ReferralManagement",
        auth: false,
        exact: true,
      },
      {
        component: "CareerManagement",
        path: "/career-management",
        componentPath: "pages/Dashboard/CareerManagement/CareerManagement",
        name: "CareerManagement",
        auth: false,
        exact: true,
      },
      {
        component: "CouponManagement",
        path: "/coupon-management",
        componentPath: "pages/Dashboard/CouponManagement/CouponManagement",
        name: "CouponManagement",
        auth: false,
        exact: true,
      },
      {
        component: "EditCouponManagement",
        path: "/edit-coupon/:id",
        componentPath: "pages/Dashboard/CouponManagement/EditCouponManagement",
        name: "EditCouponManagement",
        auth: false,
        exact: true,
      },
      {
        component: "AddCouponManagement",
        path: "/add-coupon-management",
        componentPath: "pages/Dashboard/CouponManagement/AddCouponManagement",
        name: "AddCouponManagement",
        auth: false,
        exact: true,
      },
      {
        component: "SupportManagement",
        path: "/support-management",
        componentPath: "pages/Dashboard/SupportManagement/SupportManagement",
        name: "SupportManagement",
        auth: false,
        exact: true,
      },
      {
        component: "SupportManagement",
        path: "/webinar",
        componentPath: "pages/Dashboard/SupportManagement/WebinarPage",
        name: "webinarPage",
        auth: false,
        exact: true,
      },
      {
        component: "AddNewJob",
        path: "/career-new-job",
        componentPath: "pages/Dashboard/AddNewJob/AddNewJob",
        name: "AddNewJob",
        auth: false,
        exact: true,
      },
      {
        component: "EditJob",
        path: "/career-job-edit/:id",
        componentPath: "pages/Dashboard/AddNewJob/AddNewJob",
        name: "EditJob",
        auth: false,
        exact: true,
      },
      {
        component: "EditJob",
        path: "/career-job-view/:id",
        componentPath: "pages/Dashboard/AddNewJob/AddNewJob",
        name: "EditJob",
        auth: false,
        exact: true,
      },
      {
        component: "ViewApplication",
        path: "/application-view/:id",
        componentPath: "pages/Dashboard/CareerManagement/ViewApplication",
        name: "ViewApplication",
        auth: false,
        exact: true,
      },
      {
        component: "AddCorporateManagement",
        path: "/add-corporate-management",
        componentPath:
          "pages/Dashboard/CorporateManagement/AddCorporateManagement",
        name: "AddCorporateManagement",
        auth: false,
        exact: true,
      },
      {
        component: "AddReferralManagement",
        path: "/add-referral-management",
        componentPath:
          "pages/Dashboard/ReferralManagement/AddReferralManagement",
        name: "AddReferralManagement",
        auth: false,
        exact: true,
      },
      {
        component: "SupportDetails",
        path: "/support-details/:id",
        componentPath: "pages/Dashboard/SupportManagement/SupportDetails",
        name: "SupportDetails",
        auth: false,
        exact: true,
      },
      {
        component: "UserRegister",
        path: "/user-register",
        componentPath: "pages/Dashboard/UserRegister/UserRegister",
        name: "UserRegister",
        auth: false,
        exact: true,
      },
      {
        component: "UserRegister",
        path: "/edit-user-register/:id",
        componentPath: "pages/Dashboard/UserRegister/UserRegister",
        name: "UserRegister",
        auth: false,
        exact: true,
      },
      {
        component: "DiagnosticReports",
        path: "/diagnostic-reports",
        componentPath: "pages/Dashboard/DiagnosticReports/DiagnosticReports",
        name: "DiagnosticReports",
        auth: false,
        exact: true,
      },
      {
        component: "ViewDiagnosticReports",
        path: "/reports-view/:id",
        componentPath:
          "pages/Dashboard/DiagnosticReports/ViewDiagnosticReports",
        name: "ViewDiagnosticReports",
        auth: false,
        exact: true,
      },
      {
        component: "CreateDiagnosisOrder",
        path: "/order-diagnostic",
        componentPath: "pages/Dashboard/DiagnosticReports/CreateDiagnosisOrder",
        name: "CreateDiagnosisOrder",
        auth: false,
        exact: true,
      },
      {
        component: "SubscriptionManagement",
        path: "/subscription-management",
        componentPath:
          "pages/Dashboard/SubscriptionManagement/SubscriptionManagement",
        name: "SubscriptionManagement",
        auth: false,
        exact: true,
      },
      {
        component: "ViewSubscriptionManagement",
        path: "/view-subscription-management/:id",
        componentPath:
          "pages/Dashboard/SubscriptionManagement/ViewSubscriptionManagement",
        name: "ViewSubscriptionManagement",
        auth: false,
        exact: true,
      },
      {
        component: "EditSubscriptionManagement",
        path: "/edit-subscription-management/:id",
        componentPath:
          "pages/Dashboard/SubscriptionManagement/EditSubscriptionManagement",
        name: "EditSubscriptionManagement",
        auth: false,
        exact: true,
      },
      {
        component: "AddSubscriptionManagement",
        path: "/add-subscription-management",
        componentPath:
          "pages/Dashboard/SubscriptionManagement/AddSubscriptionManagement",
        name: "AddSubscriptionManagement",
        auth: false,
        exact: true,
      },
      {
        component: "ViewOrderManagement",
        path: "/view-order",
        componentPath: "pages/Dashboard/OrderManagement/ViewOrderManagement",
        name: "ViewOrderManagement",
        auth: false,
        exact: true,
      },
      {
        component: "ViewOrderDetailManagement",
        path: "/order-detail-view/:id",
        componentPath:
          "pages/Dashboard/OrderManagement/ViewOrderDetailManagement.js",
        name: "ViewOrderDetailManagement",
        auth: false,
        exact: true,
      },
      {
        component: "CreateOrder",
        path: "/create-order",
        componentPath: "pages/Dashboard/OrderManagement/CreateOrder",
        name: "CreateOrder",
        auth: false,
        exact: true,
      },
      //FKHB
      {
        component: "PatientFKHB",
        path: "/patient-view-fk",
        componentPath:
          "pages/Dashboard/FlipkartVerticals/PatientManagementFKHB/PatientFKHB",
        name: "PatientFKHB",
        auth: false,
        exact: true,
      },
      {
        component: "PatientBulkUploadFkhb",
        path: "/patient-upload-fkhb",
        componentPath:
          "pages/Dashboard/FlipkartVerticals/PatientManagementFKHB/PatientBulkUploadFkhb",
        name: "PatientBulkUploadFkhb",
        auth: false,
        exact: true,
      },
      {
        component: "PatientProfileFKHB",
        path: "/patient-profile-fkhb/:id",
        componentPath:
          "pages/Dashboard/FlipkartVerticals/PatientManagementFKHB/PatientProfileFKHB",
        name: "PatientProfileFKHB",
        auth: false,
        exact: true,
      },
      {
        component: "PatientProfileFKHB",
        path: "/patient-profile-fkhb",
        componentPath:
          "pages/Dashboard/FlipkartVerticals/PatientManagementFKHB/PatientProfileFKHB",
        name: "PatientProfileFKHB",
        auth: false,
        exact: true,
      },
      {
        component: "AddFamilyMemberFkhb",
        path: "/add-family-fkhb",
        componentPath:
          "pages/Dashboard/FlipkartVerticals/PatientManagementFKHB/AddFamilyMemberFkhb",
        name: "AddFamilyMemberFkhb",
        auth: false,
        exact: true,
      },
      {
        component: "AppointmentFKHB",
        path: "/view-appointment-fk",
        componentPath:
          "pages/Dashboard/FlipkartVerticals/AppointmentManagementFKHB/AppointmentFKHB",
        name: "AppointmentFKHB",
        auth: false,
        exact: true,
      },
      {
        component: "CreateAppointmentFKHB",
        path: "/create-appointment-fk",
        componentPath:
          "pages/Dashboard/FlipkartVerticals/AppointmentManagementFKHB/CreateAppointmentFKHB",
        name: "CreateAppointmentFKHB",
        auth: false,
        exact: true,
      },

      {
        component: "ViewAppointmentFKHB",
        path: "/view-appointmentdetail-fk/:id",
        componentPath:
          "pages/Dashboard/FlipkartVerticals/AppointmentManagementFKHB/ViewAppointmentFKHB",
        name: "ViewAppointmentFKHB",
        auth: false,
        exact: true,
      },
    ],
  },
];
export default routers;
