import { createStore, applyMiddleware } from "redux";
import { reducers } from "../reducer";
import thunk from "redux-thunk";
import routers from "routes/routes";
import { Toast } from "service/toast";
import moment from "moment";
import axios from "axios";

import patientActive from "assets/icons/patientActive.svg";
import patientInactive from "assets/icons/patientInactive.svg";
import appointmentInactive from "assets/icons/appointmentInactive.svg";
import appointmentActive from "assets/icons/appointmentActive.svg";
import doctorActive from "assets/icons/doctorActive.svg";
import doctorInactive from "assets/icons/doctorInactive.svg";
import LeadIcon from "assets/icons/attraction.png";
import rosterActive from "assets/icons/rosterActive.svg";
import rosterInactive from "assets/icons/rosterInactive.svg";
import contentActive from "assets/icons/contentActive.svg";
import contentInactive from "assets/icons/contentInactive.svg";
import dashboardActive from "assets/icons/dashboardActive.svg";
import dashboardInactive from "assets/icons/dashboardInactive.svg";

import subscriptionActive from "assets/icons/subscriptionActive.svg";
import staffActive from "assets/icons/staffActive.svg";
import corporateActive from "assets/icons/corporateActive.svg";
import clinicalActive from "assets/icons/clinicalActive.svg";
import referralActive from "assets/icons/referralActive.svg";
import couponActive from "assets/icons/couponActive.svg";
import careerActive from "assets/icons/careerActive.svg";
import supportActive from "assets/icons/supportActive.svg";
import reportActive from "assets/icons/reportActive.svg";
import orderActive from "assets/icons/orderManage.png";
import CryptoJS from "crypto-js";

export const store = createStore(
  reducers,
  applyMiddleware(thunk.withExtraArgument({ Toast }))
);
export const history = require("history").createBrowserHistory({});

let routerCache = {},
  timer;

export const pageNavigationByName = (locationDetails) => {
  let { name: locationName, params = "", ...rest } = locationDetails;

  let pathname = "";

  let locationProps = rest || {};

  if (routerCache.hasOwnProperty(locationName)) {
    pathname = routerCache[locationName];
  } else {
    for (let i = 0; i < routers.length; i++) {
      let { name = "", childrens = [], path: parentPath } = routers[i];

      if (name === locationName) {
        pathname = parentPath;
        routerCache[locationName] = parentPath;
        break;
      } else {
        for (let j = 0; j < childrens.length; j++) {
          let { name = "", path = "" } = childrens[j];

          if (name === locationName) {
            let fullPath = `${parentPath}${path}`;
            pathname = fullPath;
            routerCache[locationName] = fullPath;
            break;
          }
        }
      }

      if (pathname) break;
    }
  }

  if (params) {
    pathname = Object.keys(params).reduce((acc, keyName) => {
      return acc.replace(`:${keyName}`, params[keyName]);
    }, pathname);
  }

  history.push({ pathname, ...locationProps });
};

export const getMonth = (month) => {
  switch (month) {
    case "Jan":
      return 0;
    case "Feb":
      return 1;
    case "Mar":
      return 2;
    case "Apr":
      return 3;
    case "May":
      return 4;
    case "Jun":
      return 5;
    case "Jul":
      return 6;
    case "Aug":
      return 7;
    case "Sep":
      return 8;
    case "Oct":
      return 9;
    case "Nov":
      return 10;
    case "Dec":
      return 11;
    default:
      return 1;
  }
};

export const dateDiff = (orderStartDate, orderEndDate = false) => {
  let finalValue;

  let currentDate = moment([
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
  ]);

  let startDate = moment([
    String(orderStartDate).split(",")[0].split(" ")[2],
    getMonth(String(orderStartDate).split(",")[0].split(" ")[1]),
    String(orderStartDate).split("th")[0],
  ]);

  let endDate = moment([
    String(orderEndDate).split(",")[0].split(" ")[2],
    getMonth(String(orderEndDate).split(",")[0].split(" ")[1]),
    String(orderEndDate).split("th")[0],
  ]);

  if (orderEndDate) {
    finalValue = endDate.diff(startDate, "days");
  } else {
    finalValue = currentDate.diff(startDate, "days");
  }

  return finalValue > 1 ? `${finalValue} days` : `${finalValue} day`;
};

export const isGeneralImage = (data) => {
  let urls = [];
  data.items.map((list) => {
    list.imgUrls.map((url) => {
      urls.push(url);
    });
  });
  let isGeneral = urls.some((list) => {
    return String(list).includes("General+New");
  });
  return isGeneral ? "Yes" : "No";
};

export const timeConvert = (time) => {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
};

export const debounce =
  (func, setLoading, timeout = 800) =>
  (...args) => {
    clearTimeout(timer);
    timer = setTimeout(async () => {
      typeof setLoading === "function" && setLoading(true);
      await func(...args);
      typeof setLoading === "function" && setLoading(false);
    }, [timeout]);
  };
export const debounceFunction = (fn, delay) => {
  let timeoutID;
  return (...args) => {
    if (timeoutID) clearTimeout(timeoutID);
    timeoutID = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};
export const downloadFile = ({ url }) => {
  if (!url) return;

  const aTag = document.createElement("a");
  aTag.href = url;
  aTag.click();
};

export function capitalizeLetter(string) {
  if (!string) return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getFirstLetter = (value) => {
  if (!value) return;
  if (Array.isArray(value)) {
    const filteredValue = value.filter(Boolean);
    let letter = "",
      lastIndex = value?.length - 1;

    if (filteredValue?.length <= 0) return;
    if (filteredValue[0]) letter += filteredValue[0]?.charAt(0)?.toUpperCase();
    if (lastIndex && filteredValue[lastIndex])
      letter += filteredValue[lastIndex]?.charAt(0)?.toUpperCase();

    return letter;
  }
  return value?.charAt(0).toUpperCase();
};

export const Encrypt = (plainText) => {
  if (!plainText) {
    return plainText;
  }
  try {
    var ciphertext = CryptoJS.AES.encrypt(
      plainText,
      "39ib92v7db292bbwqddhvsi73tw048dbi3h8hf4b9cib49"
    ).toString();

    return ciphertext;
  } catch (e) {
    console.log("Error in encryption", e);
    return plainText;
  }
};

export const navLink = [
  {
    to: "/admin/dashboard",
    label: "Dashboard",
    iconName: dashboardActive,
    inactiveIcon: dashboardInactive,
    privilegesName: "dashboard",
  },
  {
    to: "/admin/patient-management",
    label: "Patient Management",
    iconName: patientActive,
    inactiveIcon: patientInactive,
    privilegesName: "patientManagement",
  },
  {
    to: "/admin/lead-management",
    label: "Leads",
    iconName: LeadIcon,
    inactiveIcon: LeadIcon,
    privilegesName: "leadManagement",
  },
  {
    to: "/admin/smart-clinic-management",
    label: "SmartClininc",
    iconName: LeadIcon,
    inactiveIcon: LeadIcon,
    privilegesName: "smartClinicManagement",
  },
  {
    to: "/admin/Outbound_Calling",
    label: "OutBound Caliing",
    iconName: LeadIcon,
    inactiveIcon: LeadIcon,
    privilegesName: "outboundCalling",
  },
  // {
  //   to: "/admin/Configuration",
  //   label: "Configuration",
  //   iconName: LeadIcon,
  //   inactiveIcon: LeadIcon,
  //   privilegesName: "configuration",
  // },
  {
    to: "/admin/fulfillMent-management",
    label: "FullFillment",
    iconName: LeadIcon,
    inactiveIcon: LeadIcon,
    privilegesName: "FullFillment",
  },
  {
    to: "/admin/appointment",
    label: "Appointment",
    iconName: appointmentActive,
    inactiveIcon: appointmentInactive,
    privilegesName: "appointment",
  },

  {
    to: "/admin/doctor-management?tab=0&page=1",
    label: "Doctor Management",
    iconName: doctorActive,
    inactiveIcon: doctorInactive,
    privilegesName: "doctorManagement",
  },
  {
    to: "/admin/roster-management?tab=0&page=1",
    label: "Roster Management",
    iconName: rosterActive,
    inactiveIcon: rosterInactive,
    privilegesName: "rosterManagement",
  },
  {
    to: "/admin/content-management?tab=0&page=1&status=Active",
    label: "Content Management",
    iconName: contentActive,
    inactiveIcon: contentActive,
    privilegesName: "contentManagement",
  },
  {
    to: "/admin/clinical-management",
    label: "Clinical Management",
    iconName: clinicalActive,
    inactiveIcon: clinicalActive,
    privilegesName: "clinicalManagement",
  },
  {
    to: "/admin/staff-management?tab=0&page=1",
    label: "Staff Management",
    iconName: staffActive,
    inactiveIcon: staffActive,
    privilegesName: "staffManagement",
  },
  {
    to: "/admin/corporate-management",
    label: "Co-branded Page",
    iconName: corporateActive,
    inactiveIcon: corporateActive,
    privilegesName: "corporate",
  },
  {
    to: "/admin/referral-management",
    label: "Referral",
    iconName: referralActive,
    inactiveIcon: referralActive,
    privilegesName: "referral",
  },
  {
    to: "/admin/subscription-management",
    label: "Subscription Management",
    iconName: subscriptionActive,
    inactiveIcon: subscriptionActive,
    privilegesName: "subscription",
  },
  {
    to: "/admin/coupon-management",
    label: "Coupon",
    iconName: couponActive,
    inactiveIcon: couponActive,
    privilegesName: "coupon",
  },
  {
    to: "/admin/support-management?tab=0&page=1",
    label: "Support",
    iconName: supportActive,
    inactiveIcon: supportActive,
    privilegesName: "support",
  },
  {
    to: "/admin/diagnostic-reports",
    label: "Diagnostic Order Management",
    iconName: reportActive,
    inactiveIcon: reportActive,
    privilegesName: "diagnostic",
  },
  {
    to: "/admin/career-management?tab=0&page=1",
    label: "Career Management",
    iconName: careerActive,
    inactiveIcon: careerActive,
    privilegesName: "career",
  },
  {
    to: "/admin/view-order",
    label: "Medicine Order Management",
    iconName: orderActive,
    inactiveIcon: orderActive,
    privilegesName: "order",
  },
  {
    to: "/admin/patient-view-fk",
    label: "Patient Management FKHB",
    iconName: orderActive,
    inactiveIcon: orderActive,
    privilegesName: "patientFKHB",
  },
  {
    to: "/admin/view-appointment-fk",
    label: "Appointment Management FKHB",
    iconName: orderActive,
    inactiveIcon: orderActive,
    privilegesName: "appointmentFKHB",
  },
];

export const getGoogleAdress = async ({ lat, lng }) => {
  try {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${
        process.env.GOOGLE_API_KEY || "AIzaSyCPQm-Gl4aIgNuj5v8l_J34EEDfGJtZCbU"
      }`
    );
    return response;
  } catch (error) {
    console.log("error >> ", error);
    return false;
  }
};

export const Hours = [
  "12",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
];

export const Minutes = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "50",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "59",
];

export const Types = ["AM", "PM"];

export const findHour = (start, end) => {
  if (start && end) {
    var startTimeString = start;
    var endTimeString = end;

    // Convert time strings to Date objects (assuming today's date)
    var startDate = new Date("1970-01-01T" + startTimeString + ":00");
    var endDate = new Date("1970-01-01T" + endTimeString + ":00");

    // Calculate the time difference in milliseconds
    var timeDifference = endDate - startDate;

    // Convert time difference to minutes
    var differenceInMinutes = timeDifference / (1000 * 60);
    console.log("Difference in minutes:", differenceInMinutes);
    return differenceInMinutes;
    
  }
};
