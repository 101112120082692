// DEVELOPMENT

console.log("testt")
// export const baseURL = "http://doodlebluelive.com";
// export const authURL = `${baseURL}:2055/api/v1`;
// export const adminURL = `${baseURL}:2056/api/v1`;
// export const documentUrl = `${baseURL}:2057/api/v1`;
// export const contentUrl = `${baseURL}:2078/api/v1`;
// export const miscellaneousURL = `${baseURL}:2090`;
// export const baseURL = "http://localhost:4000"

//PRE PRODUCTION
// export const baseURL = "http://localhost:8000";
// export const baseURL = "http://localhost:8000";
// export const razorPayKey = "rzp_test_UmmHfd3otJYTU9";
/* The above code is declaring a constant variable `baseURL` and assigning it the value
//  "http://localhost:8000". */


//  export const baseURL = "https://apidev.meradoc.com";

//new
// export const baseURL = "https://api-preprod.meradoc.com";

// // export const authURL = `${baseURL}/user/api/v1`;
// export const authURL = `${baseURL}/user/api/v1`;
// export const adminURL = `${baseURL}/appointment/api/v1`;
// export const documentUrl = `${baseURL}/prescription/api/v1`;
// export const contentUrl = `${baseURL}/cms/api/v1`;
// export const miscellaneousURL = `${baseURL}/miscellaneous`;
// export const medPay = `${baseURL}/pharmacy/api/v1`;
// export const userBaseUrl = `${baseURL}/user/api/v1/user`;
// export const prescriptionURL = `${baseURL}/prescription/api/v1`;


//PRODUCTION
export const razorPayKey = "rzp_live_DkT1kmU4mT8Dod";

// // // v1.24 base url
// // // export const baseURL = "https://api-1-24.meradoc.com";

// // // old base url
export const baseURL = "https://api.meradoc.com";  
export const authURL = `${baseURL}/user/api/v1`;
export const adminURL = `${baseURL}/appointment/api/v1`;
export const documentUrl = `${baseURL}/prescription/api/v1`;
export const contentUrl = `${baseURL}/cms/api/v1`;
export const miscellaneousURL = `${baseURL}/miscellaneous`;
export const medPay = `${baseURL}/pharmacy/api/v1`;
export const userBaseUrl = `${baseURL}/user/api/v1/user`;
export const prescriptionURL = `${baseURL}/prescription/api/v1`;


export const endpoints = {
  dashboard: {
    GET_ALL_COUNT: `${miscellaneousURL}/count`,
    GET_ALL_COUNT_SC: `${miscellaneousURL}/sc-count`,
    GET_ALL_DUTY: `${adminURL}/slots/doctorsOnDuty/`,
  },
  staffsAndRoles: {
    ADD_ROLE: `${authURL}/roles`,
    UPDATE_ROLE: `${authURL}/roles`,
    DELTE_ROLE: `${authURL}/roles`,
    GET_ALL_ROLE: `${authURL}/roles?size=10`,
    GET_ROLE_BY_ID: `${authURL}/roles`,
    ADD_STAFF: `${authURL}/user/signup`,
    UPDATE_STAFF: `${authURL}/staff`,
    DELTE_STAFF: `${authURL}/staff`,
    GET_ALL_STAFF: `${authURL}/staff?size=10`,
    GET_STAFF_BY_ID: `${authURL}/staff`,
    ROLE_DROPDOWN: `${authURL}/roles/getRoleName`,
  },
  paymentLink: {
    SEND_USER: `${adminURL}/appointment/sendPaymentLink`
  },
  faq: {
    ADD_FAQ: `${adminURL}/faq`,
    UPDATE_FAQ: `${adminURL}/faq`,
    DELTE_FAQ: `${adminURL}/faq`,
    GET_ALL_FAQ: `${adminURL}/faq?size=20`,
    GET_FAQ_BY_ID: `${adminURL}/faq`,
    FAQ_PRIORITY: `${adminURL}/faq/updatePriority`,
    FAQ_DROPDOWN: `${adminURL}/faq/group`,
    GET_SUBCRIPTION_PLAN: `${authURL}/admin/subscription`,
    ADD_SUBCRIPTION_COUPON: `${authURL}/user/subscription/purchase/summary`,
    PURCHASE_SUBCRIPTION: `${authURL}/user/subscription/purchase`,
  },
  auth: {
    GET_CALL_LOGS_BY_ID: `${authURL}/admin/getCallLogs`,
    GET_CALL_LOGS_BY_NUMBER: `${authURL}/admin/getCallLogsByNumber`,

    MANUAL_CALL: `${authURL}/admin/manualCall`,
    GET_USER_DETAIL: `${authURL}/user/getUserById`,
    GET_USER_BY_ID: `${authURL}/user/admin`,
    UPDATE_USER: `${authURL}/user/updateAdmin`,
    CHANGE_PASSWORD: `${authURL}/user/changePasswordAdmin`,
    SIGN_IN: `${authURL}/admin/login`,
    SC_GET_OTP: `${authURL}/admin/getOTP`,
    SC_VERIFY_OTP: `${authURL}/admin/verifyOTP`,
    FORGOT_PASSWORD: `${authURL}/user/forgetPassword`,
    RESET_PASSWORD: `${authURL}/user/sendOtp`,
    VERIFY_OTP: `${authURL}/user/verifyOtpAdmin`,
    LOGOUT: `${userBaseUrl}/logout`
  },
  blog: {
    ADD_BLOG: `${adminURL}/blog`,
    UPDATE_BLOG: `${adminURL}/blog`,
    DELTE_BLOG: `${adminURL}/blog`,
    GET_ALL_BLOG: `${adminURL}/blog?size=20`,
    GET_BLOG_BY_ID: `${adminURL}/blog`,
    BLOG_DROPDOWN: `${adminURL}/blog/group`,
    BLOG_CATEGORY: `${adminURL}/blog/category/list`,
    BLOG_PRIORITY: `${adminURL}/blog/updatePriority`,
    ADD_CATEGORY: `${adminURL}/blog/category`,
    UPDATE_CATEGORY: `${adminURL}/blog/category`,
    DELETE_CATEGORY: `${adminURL}/blog/category`,
    GET_ALL_CATEGORY: `${adminURL}/blog/category?size=10`,
    GET_CATEGORY_BY_ID: `${adminURL}/blog/category`,
  },
  staffManagement: {
    GET_ALL_ROLES: `${authURL}/admin/getAllRole`,
    GET_ALL_STAFFS: `${authURL}/admin/getAllStaff`,
    ADD_ROLE: `${authURL}/admin/addRole`,
    ADD_STAFF: `${authURL}/admin/addStaff`,
    UPDATE_PENDING_APPROVAL_STATUS: `${authURL}/admin/addStatus`,
    UPDATE_STAFF_STATUS: `${authURL}/admin/addStatus`,
    GET_PENDING_APPROVAL: `${authURL}/doctor/admin/doctorManagement`,
    EDIT_ROLE: `${authURL}/admin/editRoleById`,
    EDIT_STAFF: `${authURL}/admin/editRoleById`,
    GET_ROLE: `${authURL}/admin/getRoleById`,
    GET_STAFF: `${authURL}/admin/getStaffById`,
    UPDATE_STAFF: `${authURL}/admin/updateStaff`,
  },
  careerManagement: {
    //carrerManagement
    GET_ALL_CAREER_APPLICATION: `${contentUrl}/jobRequest/admin/jobRequest/list`,
    GET_ALL_JOBS: `${contentUrl}/jobCreation/admin/jobs/list`,
    GET_JOB: `${contentUrl}/jobCreation/admin/getJob`,
    GET_APPLICATION: `${contentUrl}/jobRequest/admin/jobRequest`,
    UPDATE_JOB_STATUS: `${contentUrl}/jobCreation/admin/job/update`,
    UPDATE_RESUME_STATUS: `${contentUrl}/jobRequest/admin/jobRequest/statusUpdate`,
    CREATE_CAREER_MANAGEMENT: `${contentUrl}/jobCreation/admin/job/create`,

    ADD_ROLE: `${authURL}/admin/addRole`,
    ADD_STAFF: `${authURL}/admin/addStaff`,
    UPDATE_PENDING_APPROVAL_STATUS: `${authURL}/admin/addStatus`,
    UPDATE_STAFF_STATUS: `${authURL}/admin/addStatus`,
    GET_PENDING_APPROVAL: `${authURL}/doctor/admin/doctorManagement`,
    EDIT_ROLE: `${authURL}/admin/editRoleById`,
    EDIT_STAFF: `${authURL}/admin/editRoleById`,
    GET_ROLE: `${authURL}/admin/getRoleById`,
    GET_STAFF: `${authURL}/admin/getStaffById`,
    UPDATE_STAFF: `${authURL}/admin/updateStaff`,
  },
  patientManagement: {
    GET_ALL_PATIENTS: `${authURL}/user/admin/patientManagement/search`,
    GET_ALL_PATIENTS_SMART_CLINIC: `${authURL}/user/admin/patientManagement/search/roleId`,
    GET_PATIENT_DETAILS: `${authURL}/user/admin/patientProfile`,
    GET_PATIENT_APPOINTMENT_DETAILS: `${adminURL}/appointment/admin/appointment`,
    CHANGE_PATIENT_ACTIVITY: `${authURL}/user/admin/changeActiveStatus`,
    GET_PATIENT_REPORTS: `${documentUrl}/document/admin/documents`,
    GET_FAMILY_MEMBER_DETAILS: `${authURL}/user/admin/getFamilyMember`,
    GET_MEDICAL_HISTORY: `${authURL}/user/admin/getMedicalHistory`,
    GET_CORPORATE_LIST: `${authURL}/admin/corporate/list`,

    //prod
    BULK_UPLOAD_CSV: `${authURL}/user/admin/upload`,
    GET_PATIENT_DETAILS_TEAM_LEAD: `${authURL}/user/admin/team/patientProfile`,
    UPDATE_PATIENT_DETAILS_TEAM_LEAD: `${authURL}/user/admin/team/update/patientProfile`,
    GET_CALL_LOGS_TABLE: `${authURL}/user/admin/team/getcallLogs`,
    BULK_UPLOAD_CSV_MANUALLY_CALL: `${authURL}/user/admin/upload/patientCalling`,
    ONBOARD_PROFILE_BY_QUERY: `${authURL}/user/admin/team/onboardProfile`,
    BULK_UPLOAD_REPORTS_UPLOAD: `${prescriptionURL}/prescription/uploadMultipleDocuments`,
    BULK_UPLOAD_LAB_ORDER: `${prescriptionURL}/prescription/insertBulkLabOrder`,

    // dev
    // BULK_UPLOAD_CSV: `http://localhost:2002/api/v1/user/admin/upload`,
    // BULK_UPLOAD_CSV_MANUALLY_CALL: `http://localhost:2002/api/v1/user/admin/upload/patientCalling`,
    // BULK_UPLOAD_REPORTS_UPLOAD: `http://localhost:2057/api/v1/prescription/uploadMultipleDocuments`,
    // BULK_UPLOAD_LAB_ORDER: `${prescriptionURL}/prescription/insertBulkLabOrder`,


    BULK_UPLOAD_LOGS: `${authURL}/user/admin/upload/logs`,
    RESEND_PRESCRIPTION: `${documentUrl}/prescription/prescription/resend`,
    DOWNLOAD_CSV: `${authURL}/user/admin/download/patient`,
    REGISTER_NEW_USER: `${authURL}/user/register/patient`,
    GET_DIAGONSIS_REPORT_ORDER: `${documentUrl}/diagonostic/myOrder/diagonosticList`,
    GET_SINGLE_REPORT_ORDER: `${medPay}/diagnostic/order`,
    BULK_UPLOAD_DIAGONSIS_REPORT: `${documentUrl}/diagonostic/updatediagonostic`,
    BULK_REPORT_UPOAD: `${documentUrl}/document/document/bulk`,
    GET_SELECTED_REGISTER_USER: `${authURL}/user/userProfile`,
    EDIT_REGISTER_USER: `${authURL}/user/editProfile`,
    GET_FAMILY_RELATIONS: `${authURL}/user/family/relations`,
    UPLOAD_IMAGE: `${documentUrl}/prescription/upload`,
    ADD_FAMILY_MEMBER: `${authURL}/user/addFamilyMember`,
    EDIT_FAMILY_MEMBER: `${authURL}/user/familyMember`,
    GET_SUBCRIPTION_PLAN: `${authURL}/admin/subscription`,
    PURCHASE_SUBCRIPTION: `${authURL}/user/subscription/purchase`,
    GET_COUPON_APPLY_STATUS: `${authURL}/user/subscription/purchase/summary`,
    GET_FAMILY_DATA_BASED_ON_MOBILE_NUMBER: `${authURL}/user/patient/byMobile`,
    GET_REFERRAL_LIST: `${authURL}/user/admin/referralList`,
    GET_BY_PINCODE: `${authURL}/user/searchPincode`,
    GET_FAMILY_MEMBERS: `${authURL}/user/getFamilyMember`,
    GET_FAMILY_MEMBERS_BY_COND: `${authURL}/user/getFamilyMemberByCond`,
    UPDATE_MEDICAL_HISTORY: `${userBaseUrl}/createMedicalHistory`,
    GET_SUBCRIPTION_BY_USER_ID: `${authURL}/user/subscription/getById`,
    GET_TRAVEL_USER_DETAIL: `${authURL}/user/sso/getTravelUserDetail`,
  },
  inboundAPIs: {
    GET_FILTER_VALUES: `${documentUrl}/inbound/filterValues`,
    GET_SELECT_VALUES: `${documentUrl}/inbound/selectValues`,
    CREATE_INBOUND_DATA: `${documentUrl}/inbound/create`,
    UPDATE_INBOUND_DATA_BY_ID: `${documentUrl}/inbound/update`,
    GET_INBOUNDDATA_BY_ID: `${documentUrl}/inbound/getById`,
    GET_ALL_LEADS: `${documentUrl}/inbound/getAll`,
    GET_ALL_RESPECTIVE_LEADS: `${documentUrl}/inbound/getLeadsByQuery`,
    //prod
    GET_INBOUNDDATA_BY_PATIENT_NUMBER: `${documentUrl}/inbound/getByPatientNumber`,
    GET_MANUAL_LEAD_OPTION: `${documentUrl}/inbound/createManualLeadOption`,
    GET_MESSAGES:`${userBaseUrl}/whatsAppMessageUser`
    //dev
    // GET_INBOUNDDATA_BY_PATIENT_NUMBER:`http://localhost:2057/api/v1/inbound/getByPatientNumber`,
  },
  smartClinic: {
    GET_ALL_LEADS: `${authURL}/admin/getAllStores`,
    // GET_BY_ID: `${userBaseUrl}/admin/get/smartClinicForm`,
    GET_QUESTIONS: `${userBaseUrl}/admin/getAll/smartClinicQuestions`,
    CREATE_FORM: `${userBaseUrl}/admin/create/smartClinicForm`,
    BULK_UPLOAD: `${userBaseUrl}/admin/upload/smartClinic`,
    ADD_STORE: `${authURL}/admin/addStore`,
    GET_BY_ID: `${authURL}/admin/getStoreById`
  },
  fulFillment: {
    GET_ALL_LEADS: `${documentUrl}/inbound/getAllLeadCart`,
    GET_BY_ID: `${documentUrl}/inbound/getLeadCart`,
    UPDATE_BY_ID: `${documentUrl}/inbound/updateLeadCart`,
    CREATE_LEAD: `${documentUrl}/inbound/createLeadCartManual`,
    SEND_PAYMENT_NOTIFICATION: `${documentUrl}/inbound/sendPaymentNotification`,
    CREATE_ORDER_RAZORPAY: `${documentUrl}/inbound/razorPaycreateOrder`,
  },

  appointment: {

    GET_PATIANT_BY_ID: `${userBaseUrl}/userProfile`,

    GET_PATIANT_BY_CONDITION_BASE: `${userBaseUrl}/getUserBaseCondition`,

    GET_PRESCRIPTION_BY_ID: `${prescriptionURL}/prescription/prescriptionData`,
    GET_ALL_APPOINTMENT: `${adminURL}/appointment/appointments`,
    GET_APPOINTMENT: `${adminURL}/appointment/appointment/`,
    CANCEL_APPOINTMENT: `${adminURL}/appointment/appointment-cancel/`,
    RESCHEDULE_APPOINTMENT: `${adminURL}/appointment/appointment-update/`,
    GET_USER_DATA_BASED_ON_MOBILE_NUMBER: `${authURL}/user/admin/patient/byMobile`,
    GET_USER_DATA_BASED_ON_MOBILE_NUMBER_ACCOUNT: `${authURL}/user/signIn/patient/byMobile`,
    CREATE_APPOINTMENT: `${adminURL}/appointment/appointment-create`,
    GET_GP_SLOTS: `${adminURL}/slots/available/general`,
    GET_APPOINTMENT_SETTINGS: `${adminURL}/appointment/setting/get`,
    GET_FAMILY_DETAIL: `${adminURL}/appointment/appointments`,
    GET_PREBOOK_APPOINTEMENT: `${adminURL}/appointment/preBookingDisplay`,
    GET_USER_PROFILE: `${authURL}/user/admin/UserProfile/`,
    UPDATE_MEMBER_ADDED: `${adminURL}/appointment/updateAppointment`,
    UPLOAD_DOC_APPOINTMENT: `${adminURL}/appointment/appointment-update-documents/`,
    List_SPECIALITY: `${authURL}/doctor/listSpecialty`,
    GET_SP_SLOTS: `${adminURL}/slots/available/specialist`,
    GET_SPECIALIST_BY_ID: `${authURL}/doctor/findDoctor`,
    GET_ALL_DOCTOR: `${authURL}/doctor/approvedDoctors`,
    GET_VOICE_CALL: `${adminURL}/appointment/logs/voice/list`,
    GET_VOICE_CALL_BY_ID: `${adminURL}/appointment/logs/voice`,
    GET_VIDEO_CALL: `${adminURL}/appointment/logs/video/list`,
    GET_VIDEO_CALL_BY_ID: `${adminURL}/appointment/logs/video`,
    GET_PREFERRED_GP_LANG: `${authURL}/doctor/language/list/admin`,
    GET_DOCTOR_NEXT_AVAILABLE: `${adminURL}/slots/next/available`,
    GET_ALL_REFERRAL: `${authURL}/user/admin/allReferralList`,
    GET_PATIENT_SUBCRIPTION: `${authURL}/user/subscription/invoiceInfo`,
    APPOINTMENTS_BY_COUPON_ID: `${adminURL}/appointment/byCoupon`,
    GET_DOCTORS_NOTE: `${adminURL}/appointment/patientDoctors_Notes`,
    manualCallDial: `${adminURL}/appointment/exotelManualCall`,
    ADD_NEW_DRUG: `${documentUrl}/drug/drugs`,
    GET_APPOINTMENT_BY_PATIENT_ID: `${adminURL}/appointment/internal/getAppointmentByPatient`,
    GET_FAMILY_MEMBERS_APT_BY_ID: `${adminURL}/appointment/admin/familyMembers_Appointment`

  },
  slot: {
    GET_ALL_SLOT_BASED_ON_DOCTOR: `${adminURL}/slots/listAllDoctorSlots/`,
    GET_DIAGNOSTIC_SLOTS: `${medPay}/diagnostic/slot/availability`,
    GET_CART_ADDRESS: `${authURL}/user/address/view`,
    CANCER_DIAGNOSTIC_ORDER: `${medPay}/diagnostic/order/cancel`,
    GET_ALL_CITIES_LIST: `${medPay}/diagnostic/cities`,
    GET_SEARCH_PARTNER_TEST: `${medPay}/diagnostic/tests/search`,
    ADD_NEW_TEST: `${medPay}/diagnostic/tests/insert`,
    CALCULATE_DIAGNOSTIC_CART: `${medPay}/diagnostic/admin/calculateOrder`,
    DIAGNOSTIC_CREATE_ORDER: `${medPay}/diagnostic/admin/order`,
    GET_TEST_BASED_CITY: `${medPay}/diagnostic/tests/city`,
    GET_MANUAL_LAB_PARTNERS: `${medPay}/diagnostic/manual/lab/partners`,
    DIAGNOSTIC_UPDATE_ORDER: `${medPay}/diagnostic/order/update`,
    GET_REDCLIFF_DIAGNOSTIC_SLOTS: `${medPay}/diagnostic/slot-redcliffe/availability`,
  },
  clinicalManagement: {
    GET_ALL_DATA: `${adminURL}/specialty/getAll`,
    GET_DATA: `${adminURL}/specialty/get/`,
    UPDATE_DATA: `${adminURL}/specialty/update`,
    DELETE_DATA: `${adminURL}/specialty/delete`,
    CREATE_DATA: `${adminURL}/specialty/create`,
  },
  roaster: {
    GET_ALL_DATA: `${adminURL}/slots/roster/list`,
    GET_ALL_DOCTOR: `${authURL}/doctor/approvedDoctors`,
    GET_ALL_SPECIALITY: `${adminURL}/specialty/list`,
    GET_ALL_SLOTS: `${adminURL}/slots/list`,
    SLOTS_CREATE: `${adminURL}/slots/create`,
    SLOTS_UPDATE: `${adminURL}/slots/update`,
    CREATE_SLOTS: `${adminURL}/slots/create`,
    BULK_UPLOAD_LOGS: `${adminURL}/slots/admin/upload/logs`,
    BULK_UPLOAD_CSV: `${adminURL}/slots/admin/upload`,
  },
  contentManagement: {
    GET_ALL_BLOGS: `${contentUrl}/blog`,
    DELETE_BLOG: `${contentUrl}/blog`,
    DELETE_POLICY: `${contentUrl}/cms/policy/delete`,
    GET_ALL_PRIVACY_POLICY: `${contentUrl}/cms/policy`,
    ADD_POLICY: `${contentUrl}/cms/policy`,
    ADD_BLOG_CONTENT: `${contentUrl}/blog`,
    GET_ALL_CATEGORY: `${contentUrl}/blog/category/list`,
    UPDATE_BLOG: `${contentUrl}/blog`,
    UPDATE_POLICY: `${contentUrl}/cms/policy`,
    GET_POLICY: `${contentUrl}/cms/policy`,
    GET_BLOG: `${contentUrl}/blog`,
    IMAGE_UPLOADS: `${documentUrl}/prescription/upload`,
  },
  doctorManagement: {
    INVITE_DOCTOR: `${authURL}/admin/inviteDoctor`,
    GET_ALL_DOCTOR: `${authURL}/doctor/admin/doctorManagement`,
    UPLOAD_IMAGE: `${documentUrl}/prescription/upload`,
    UPLOAD_PROFILE_IMAGE: `${documentUrl}/prescription/upload/image`,
    GET_EDUCATION_DETAILS: `${authURL}/doctor/getDoctorEducationDetails`,
    UPDATE_DOCTOR_STATUS: `${authURL}/doctor/admin/doctorManagement/updateStatus`,
    GET_PERSONAL_DETAILS: `${authURL}/doctor/getPersonalDetails`,
    GET_ADDRESS_DETAILS: `${authURL}/doctor/getDoctorAddressDetails`,
    GET_PAYMENT_DETAILS: `${authURL}/doctor/getDoctorPaymentDetails`,
    GET_APPOINTMENT_DETAILS: `${adminURL}/appointment/setting/get`,
    GET_ROSTER_DETAILS: `${adminURL}/slots/list`,
    CREATE_PAYMENT: `${authURL}/addDoctorPaymentDetails`,
    CREATE_APPOINTMENT_SETTINGS: `${adminURL}/appointment/setting/create`,
    UPDATE_PERSONAL_DETAILS: `${authURL}/doctor/updateDoctorPersonalDetails`,
    UPDATE_EDUCATION_DETAIL: `${authURL}/doctor/updateDoctorEducationDetails`,
    UPDATE_APPOINTMENT: `${adminURL}/appointment/setting/update`,
    UPDATE_PAYMENT_DETAILS: `${authURL}/doctor/updateDoctorPaymentDetails`,
    GET_APPOINTMENT_SETTINGS: `${adminURL}/appointment/setting/get`,
    ADD_BASIC_INFO: `${authURL}/doctor/addBasicInfo`,
    ADD_EDUCATION_DETAILS: `${authURL}/doctor/addDoctorEducation`,
    ADD_PAYMENT_DETAILS: `${authURL}/doctor/addDoctorPaymentDetails`,
    ADD_APPOINTMENT: `${adminURL}/appointment/setting/create`,
    CREATE_EMERGENCY: `${adminURL}/slots/emergency/create`,
    BULK_UPLOAD_CSV: `${authURL}/doctor/admin/upload`,
    BULK_UPLOAD_LOGS: `${authURL}/doctor/admin/upload/logs`,
    DOWNLOAD_CSV: `${authURL}/doctor/admin/download/doctor`,
    DELETE_DOCTOR: `${authURL}/admin/deleteDoctor`,
  },
  settingsManagement: {
    GET_SETTINGS: `${authURL}/admin/getEmailSmsStatus`,
    UPDATE_PASSWORD: `${authURL}/admin/changePassword`,
    UPDATE_NOTIFICATION: `${authURL}/admin/changeEmailSmsStatus`,
  },
  corporate: {
    GET_ALL_DATA: `${authURL}/admin/corporate/list`,
    GET_FILTER_PATIENT: `${authURL}/user/admin/corporateList`,
    CREATE_CORPORATE: `${authURL}/admin/corporate/create`,
    DELETE_CORPORATE: `${authURL}/admin/corporate/delete/`,
  },
  referralManagement: {
    GET_ALL_DATA: `${authURL}/admin/referral/list`,
    CREATE_REFERRAL: `${authURL}/admin/referral/create`,
    DELETE_REFERAL: `${authURL}/admin/referral/delete`,
    GET_REFERRAL_COUNT: `${authURL}/admin/referral`
  },
  supportManagement: {
    GET_ALL_MED_DATA: `${miscellaneousURL}/api/v1/ivr/callListByType`,
    GET_ALL_DOC_DATA: `${adminURL}/appointment/support/get/list`,
    GET_SINGLE_MED_DATA: `${miscellaneousURL}/api/v1/ivr/callListById`,
    GET_SINGLE_DOC_DATA: `${adminURL}/appointment/support/getById`,
    UPDATA_DOC_DATA: `${adminURL}/appointment/support/update`,
    UPDATE_MED_DATA: `${miscellaneousURL}/api/v1/ivr/update`,
    UPDATE_WEBINAR_FIELD: `${authURL}/user/saveWebinarField`,
    GET_WEBINAR_FIELD: `${authURL}/user/webinar/data`
  },
  couponManagement: {
    COUPON_LIST: `${adminURL}/coupon/list`,
    GET_COUPON: `${adminURL}/coupon/get`,
    DELETE_COUPON: `${adminURL}/coupon/delete`,
    UPDATE_COUPON: `${adminURL}/coupon/update`,
    CREATE_COUPON: `${adminURL}/coupon/create`,
    AVAILABLE_SUBSCRIPTION_LIST: `${authURL}/admin/subscription`,
  },
  subscriptionManagement: {
    SUBSCRIPTION_LIST: `${authURL}/admin/subscription/list`,
    GET_SUBSCRIPTION: `${authURL}/admin/subscription`,
    UPDATE_SUBSCRIPTION: `${authURL}/admin/subscription/update`,
    CREATE_SUBSCRIPTION: `${authURL}/admin/subscription/create`,
    UPLOAD_IMAGE: `${documentUrl}/prescription/upload`,
    GET_SUBSCRIPTION_LIST: `${authURL}/user/subscription/list`,
  },
  callAutomation: {
    CALL_AUTO: `${authURL}/admin/callAutomation`
  },
  orderManagement: {
    GET_ALL_NUMBERS: `${medPay}/prescription/getAllNumbers`,
    GET_ALL_ORDER: `${documentUrl}/pharmacy/myOrder/medicineList`,
    GET_ALL_COUPONS: `${adminURL}/coupon/listPharmacy`,
    GET_OREDER_BY_ID: `${medPay}/orders`,
    CANCEL_ORDER: `${medPay}/orders/cancel`,
    APPROVE_OREDR: `${medPay}/orders/confirm`,
    GET_SEARCH_MEDICINE: `${medPay}/medpay/searchMedicine`,
    CALCULATE_MEDPAY_CART: `${medPay}/orders/admin/calculate`,
    CREATE_ORDER: `${medPay}/orders/admin/create`,
    GET_ALL_MEDICINE_ADDRESS: `${authURL}/user/address/list`,
    DELETE_USER_ADDRESS_MEDPAY: `${authURL}/user/address/delete`,
    ADD_NEW_ADDRESS_MEDPAY: `${authURL}/user/address/create`,
    UPDATE_ADDRESS_MEDPAY: `${authURL}/user/address/update`,
    GET_ORDER_USER: `${authURL}/user/admin/userProfile`,
    GET_ORDER_COUPON: `${adminURL}/coupon/getByCode`,
    APPROVE_OREDR: `${medPay}/orders/confirm`,
  },
  medicineDetails: {
    byNumber: `${medPay}/prescription/getPrescription`
  },
  uploadLabOrderReport: {

  }
};
