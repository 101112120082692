import axios from "axios";
import crypto from "crypto"
// import { history } from "../service/helpers";
import { history } from "helpers";
import { logoutapi } from "./Auth";
import jsonexport from 'jsonexport';
import moment from "moment";

//axios Instance
export const axiosInstance = axios.create({
  // baseURL: process.env.REACT_APP_BASE_URL,
  headers: {},
});

//logout
export const logout = () => {
  logoutapi();
  axiosInstance.defaults.headers.common["Authorization"] = "";
  localStorage.clear();
  history.push("/");
  // window.location.reload(true);
};
const decryptObject = (encryptedObject, key) => {
  const decipher = crypto.createDecipheriv('aes-256-cbc', key, Buffer.from(encryptedObject.iv, 'hex'));
  let decryptedData = decipher.update(encryptedObject.encryptedData, 'hex', 'utf8');
  decryptedData += decipher.final('utf8');

  return JSON.parse(decryptedData);
}
export const decryptArrayOfObjects = (encryptedArray, key) => {
  return encryptedArray.map((encryptedObject) => decryptObject(encryptedObject, key));
}
export const convertToCSV = (data) => {
  return new Promise((resolve, reject) => {
    jsonexport(data, function (err, csv) {
      if (err) {
        reject(err);
      } else {
        resolve(csv);
      }
    });
  });
}

export const handleDownload = async (jsonData) => {
  try {
    // const jsonData = [
    //   { name: 'John Doe', age: 25, email: 'johndoe@example.com' },
    //   { name: 'Jane Smith', age: 30, email: 'janesmith@example.com' }
    // ];

    const csv = await convertToCSV(jsonData);

    const blob = new Blob([csv], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', "Lead_Data_" + moment().format('YYYY-MM-DD HH:mm:ss'));
    link.click();

  } catch (error) {
    console.error('Error converting JSON to CSV:', error);
  }
};

export const clickDebounce = (func, delay) => {
  let timeoutId;

  return function () {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(func, delay);
  };
}
