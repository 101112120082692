import React, { useEffect, useState } from "react";
import bell from "assets/icons/bell.png";
import Man from "assets/images/person.jpg";
import DownCaret from "assets/icons/Triangle.svg";
import { Dropdown } from "react-bootstrap";
import "./header.scss";
import { logout } from "service/utilities";
import { Avatar } from "@material-ui/core";
import { Toast } from "service/toast";
import { getStaffApi } from "service/StaffManagement";
import { decodeJWT } from "service/helpers";
import { AddStaffDetails } from "action/home";
import { useDispatch } from "react-redux";

const Header = () => {

  const [data, setData] = useState({});
  const dispatch= useDispatch()

  useEffect(() => {
    getAdminData();
  }, []);

  const getAdminData = async () => {
    
    const adminData = decodeJWT(localStorage.getItem('token'));
    
    if (adminData?.id) {
      const response = await getStaffApi(adminData?.id);
      console.log(response,"response")
      dispatch(AddStaffDetails(response?.data?.data))
      setData(response?.data?.data ?? {});
    }
  }

  return (
    <>
      <div className="top-bar">
        <div className="userHeader py-4">
          {/* <img src={bell} /> */}
          {/* <Avatar /> */}
          <p>{data?.staffName ?? 'Admin'}</p>
          <Dropdown className="custom-dropdown mt-1">
            <Dropdown.Toggle id="dropdown-basic">
              <img src={DownCaret} width={12} />
              {/* <span onClick={() => setisdropToggle(!isdropToggle)}>
                {isdropToggle ? "down" : "up"}
              </span> */}
            </Dropdown.Toggle>
            <Dropdown.Menu id="drop">
              <Dropdown.Item href="#" onClick={() => {
                logout();
                Toast({ type: "success", message: "Logged Out successfully " });
              }}>
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </>
  );
};
export default Header;
