import { decodeJWT } from "service/helpers";
import { privilegesData } from "action/home";
import { getToken } from "service/helpers/utilities";
import { editRoleApi } from "service/StaffManagement";

const makeTrue = obj => {
  if (typeof obj === "object") {
    obj.create = true;
    obj.view = true;
    obj.edit = true;
    obj.delete = true;
  }
  return obj;
};

const adminPrivileges = () => async (dispatch, getState) => {
  const token = getToken();
  const docodedToken = decodeJWT(token) ?? {};

  const { roleId } = docodedToken;
  if (!roleId) return;

  const { data: { data, status, data: { hasAllAccess = false } = {} } = {} } =
    await editRoleApi({ roleId });
  let filteredData = data;

  if (status !== 200) return;

  if (hasAllAccess && filteredData) {
    for (const key of Object.keys(filteredData)) {
      makeTrue(filteredData[key]);
    }
  }

  dispatch(privilegesData(filteredData));
  return filteredData;
};

export default adminPrivileges;
