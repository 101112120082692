import axios from "axios";
import { endpoints } from "config/api";
import { axiosErrorHandler } from "./helpers/axiosHelpers";

export const getAllRoleApi = async ({ size, page, searchName }) => {
  let query = "";
  if (page) query += `&page=${page}`;
  if (size) query += `&limit=${size}`;
  if (searchName && searchName.toString().trim() !== "")
    query += `&name=${searchName}`;

  let request = await axios({
    method: "get",
    url: `${endpoints.staffManagement.GET_ALL_ROLES}?${query}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  }).catch(axiosErrorHandler);
  return request;
};

export const getAllStaffApi = async ({ size, page, searchName }) => {
  let query = "";
  if (page) query += `&page=${page}`;
  if (size) query += `&limit=${size}`;
  if (searchName && searchName.toString().trim() !== "")
    query += `&name=${searchName}`;

  let request = await axios({
    method: "get",
    url: `${endpoints.staffManagement.GET_ALL_STAFFS}?${query}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  }).catch(axiosErrorHandler);
  return request;
};

export const getPendingApprovalApi = async (params) => {
  // let query = "&status=PENDING";
  // if (page) query += `&page=${page}`;
  // if (size) query += `&limit=${size}`;
  // if (startDate) query += `&startDate=${startDate}`;
  // if (endDate) query += `&endDate=${endDate}`;

  let request = await axios({
    method: "get",
    url: `${endpoints.staffManagement.GET_ALL_STAFFS}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    params: params,
  }).catch(axiosErrorHandler);
  return request;
};

export const addRoleApi = async (data) => {
  let request = await axios({
    method: "post",
    url: `${endpoints.staffManagement.ADD_ROLE}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: data,
  }).catch(axiosErrorHandler);
  return request;
};

export const addStaffApi = async (data) => {
  let request = await axios({
    method: "post",
    url: `${endpoints.staffManagement.ADD_STAFF}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: data,
  }).catch(axiosErrorHandler);
  return request;
};

export const updatePendingApprovalStatusApi = async (data) => {
  let request = await axios({
    method: "post",
    url: `${endpoints.staffManagement.UPDATE_PENDING_APPROVAL_STATUS}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: data,
  }).catch(axiosErrorHandler);
  return request;
};

export const updateStaffApprovalStatusApi = async (data) => {
  let request = await axios({
    method: "post",
    url: `${endpoints.staffManagement.UPDATE_STAFF_STATUS}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: data,
  }).catch(axiosErrorHandler);
  return request;
};

export const editRoleApi = async (data) => {
  let request = await axios({
    method: "put",
    url: `${endpoints.staffManagement.EDIT_ROLE}?roleId=${data?.roleId}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: data,
  }).catch(axiosErrorHandler);
  return request;
};

export const editStaffApi = async (data) => {
  let request = await axios({
    method: "put",
    url: `${endpoints.staffManagement.EDIT_STAFF}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: data,
  }).catch(axiosErrorHandler);
  return request;
};
export const updateStaffApi = async (data) => {
  let request = await axios({
    method: "put",
    url: `${endpoints.staffManagement.UPDATE_STAFF}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: data,
  }).catch(axiosErrorHandler);
  return request;
};

export const getStaffApi = async (staffId) => {
  let request = await axios({
    method: "get",
    url: `${endpoints.staffManagement.GET_STAFF}?staffId=${staffId}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  }).catch(axiosErrorHandler);
  return request;
};

export const getRoleApi = async (roleId) => {
  let request = await axios({
    method: "get",
    url: `${endpoints.staffManagement.GET_ROLE}?roleId=${roleId}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  }).catch(axiosErrorHandler);
  return request;
};
